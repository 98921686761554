
.qualityCard{
    max-width: 100%;
    display: inline-block;
   
    
    .qualityCardImg{
        overflow: hidden;
        width: auto;
        max-height: 400px;
        margin-bottom: 30px;
        float: unset;

    }
    .qualityCardImg img{
        width: 100%;
        height: unset;
    }
    
    .qualityCardInfo{
        padding: 0 20px;
        display: inline-block;
        
        .qualityTitle{
            position: relative;
            display: inline-flex;
            font-family: 'Poppins', sans-serif;
            font-size: 40px;
            font-weight: 700;
            color: #354b84;
            
            
            .qualityNumber{
                position: absolute;
                text-align: center;
                bottom: 70px;
                display: inline-block;
                width: 55px;
                height: 55px;
                padding: 17px;
                background-color: #354b84;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                color: #ffffff;
                margin-bottom: unset;
            }
        }
        .qualityText {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            color: black;
            font-weight: 600px;
            max-width: unset;
        }

    }

    
    .qualityLineDiv{
        display: block;
        width: 30px;
        height: 5px;
        border-radius: 2.5px;
        background-color: #354b84;
        margin: 30px auto 30px; 
    }


    @media screen{
        @media (min-width: 768px) {
            
            .qualityCardInfo{
                
                .qualityTitle{
                    font-size: 44px;
                    
                    .qualityNumber{
                        width: 60px;
                        height: 60px;
                        padding: 18px;
                        font-size: 18px;
                    }

                }

                .qualityText{
                    max-width: 700px;
                    font-size: 19px;

                }

                .qualityNumber{
                    position: absolute;
                    text-align: center;
                    bottom: 70px;
                    display: inline-block;
                    width: 55px;
                    height: 55px;
                    padding: 17px;
                    background-color: #354b84;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    color: #ffffff;
                }
                .qualityLineDiv{
                    margin: 30px 0;
                }
            }
        }
        @media (min-width: 1024px) {
            width: 100%;
            position: relative;

            .qualityCardImg {
                width: 100%;
                max-width: 50%;
                display: inline-block;
                margin: 0;
                height: 387px;
                float: left;
            }

            .qualityCardInfo {
                max-width: 485px;
                display: inline-block;
                height: 220px;
                margin: 100px 0 0 25px;



                .qualityNumber{
                    top: 0; 
                    left:-75px;
                }
                
            
                .qualityText{
                width: 100%;

                }
            }

            &:nth-child(even) {
                display: inline-block;

                .qualityCardImg {
                    float: right;
                }
                .qualityCardInfo{
                    text-align: right;
                    float: unset;
                    margin: 100px 25px 0 0;


                    
                }
                .qualityLineDiv{
                    float: right;
                }
                .qualityNumber{
                    position: absolute;
                    left: unset;
                    right: -75px;

                    
                }
            }

        }
    }
}