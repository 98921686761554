.jumbotronhome {
    height: 450px;
    background-image: url("/src/img/jumbotronHome.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    h1 {
      font-family:  'Poppins', sans-serif;
      font-weight: 600;
      color: #fff;
      text-shadow: 4px 2px 3px #000000;
      text-align: center;
    }
}
