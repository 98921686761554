.representativesMap {
    padding: 0 5%;
    width: 100%;
    align-content: center;
    content: initial;
    margin: 0;
    
    img{
        width: 100%;
    }
    
    @media screen {
        @media (min-width: 1024px){
            padding: 0 18% 0 0;
        }
    }
}