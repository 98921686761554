.homeQuality{
    padding: 20px;

    .homeText{
        max-width: 800px;
        margin: 0 auto 50px;
    }

    .homeQualityInfos{
        display: grid;

        .homeQualityCard{
            margin-bottom: 30px;
            .certifiedBox{
                display: flex;
                align-items: center;

                .certifiedImage{
                    max-width: 120px;
                    margin-right: 10px;
                }
                .certifiedLabel{
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                    line-height: 1.7;
                    color: #6d7783;
                }   
            }

            .homeQualityTitle{
                font-family: 'Oswald', sans-serif;
                font-size: 24px;
                font-weight: 600;
                color: #354b84;
                text-transform: uppercase;
                margin-bottom: 30px;
                position: relative;
                
                &::after{
                    content: "";
                    display: block;
                    width: 60px;
                    height: 3px;
                    background-color: #071fc8;
                    position: absolute;
                    bottom: -13px;
                    left: 0;
                }
            }
            .homeQualityText{
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                line-height: 1.8;
                color:#54565a;
                margin-bottom: 10px;
            }
        }
    }
    @media screen {
        @media (min-width: 1024px){
            .homeQualityInfos{
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 50px;

            }
        }
    }
}