.homeHistory{
    margin-bottom: 50px;
    padding: 20px 0;

    .historyInfo{
        padding: 0 20px;
    }

    @media screen {
        @media (min-width: 1024px){
            display: grid;
            grid-gap: 50px;
            grid-template-columns: 1fr 1fr;
            padding: 50px 0;
            position: relative;

            .historyInfo{
                .homeTitle, .homeText{
                    text-align: start;
                }
            }

            .historyImageBox{
                height: 100%;
                overflow: hidden;

                .historyImage{
                    max-width: unset;
                    max-height: 100%;
                }
            }
        }
    }
}
