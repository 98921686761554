.homeTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color:#54565a;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
}

.homeText {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color:#54565a;
    text-align: center;
    margin-bottom: 15px;
}