@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Oswald:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:400,600,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

img {
  max-width: 100%; }

li {
  list-style-type: none; }

button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent; }

.container {
  max-width: 1024px;
  margin: auto; }

.jumbotron {
  height: 450px;
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .jumbotron h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    text-shadow: 4px 2px 3px #000000;
    text-align: center; }

.textoIsodiv {
  width: 139px;
  height: 110px;
  text-align: center;
  display: inline-block;
  position: relative;
  top: -20px;
  left: 10px; }
  .textoIsodiv .textoseloiso {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 2;
    text-align: center;
    color: #6d7783; }

.Iso9001 {
  width: 127px;
  height: 109px; }

.contactInfo {
  padding: 50px 20px;
  width: 100%; }
  .contactInfo .contactCard {
    margin-bottom: 40px; }
    .contactInfo .contactCard h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
      color: #363d4f;
      border-bottom: 4px solid #071fc8;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .contactInfo .contactCard p {
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      color: #6d7783; }

@media screen and (min-width: 768px) {
  .contactInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px; } }

@media screen and (min-width: 1024px) {
  .contactInfo {
    display: block;
    max-width: 320px;
    float: right; } }

.contactButton {
  padding: 12px 50px;
  background-color: #354b84;
  font-family: 'Oswald', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase; }

.contactForm {
  padding: 20px; }
  .contactForm input, .contactForm textarea {
    display: block;
    width: 100%;
    border: solid 1px #cacaca;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 20px; }
    .contactForm input::-webkit-input-placeholder, .contactForm textarea::-webkit-input-placeholder {
      color: #aeb7c1;
      padding: 10px; }
    .contactForm input:-ms-input-placeholder, .contactForm textarea:-ms-input-placeholder {
      color: #aeb7c1;
      padding: 10px; }
    .contactForm input::-ms-input-placeholder, .contactForm textarea::-ms-input-placeholder {
      color: #aeb7c1;
      padding: 10px; }
    .contactForm input::placeholder, .contactForm textarea::placeholder {
      color: #aeb7c1;
      padding: 10px; }
  .contactForm textarea {
    height: 160px; }

@media screen and (min-width: 768px) {
  .contactForm input {
    display: inline-flex;
    width: calc(50% - 10px); }
    .contactForm input:first-child {
      margin-right: 20px; } }

@media screen and (min-width: 1024px) {
  .contactForm {
    width: calc(100% - 320px);
    padding-top: 50px; } }

.jumbotronhome {
  height: 450px;
  background-image: url("/src/img/jumbotronHome.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .jumbotronhome h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    text-shadow: 4px 2px 3px #000000;
    text-align: center; }

.footerRasil {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #354b84;
  padding: 20px 25px 0 25px; }
  .footerRasil .logoRasil {
    text-align: center;
    margin-bottom: 10px; }
    .footerRasil .logoRasil h1 {
      font-family: 'Open Sans';
      font-size: 22px;
      font-weight: 500;
      color: #ffffff; }
      .footerRasil .logoRasil h1 strong {
        font-family: 'Montserrat';
        font-size: 23px;
        font-weight: 700; }
  .footerRasil .footerAddress {
    position: relative;
    display: inline-flexbox;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    font-size: 13px;
    font-weight: 200;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    margin-bottom: 15px; }
  .footerRasil .footerNavList {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-align: center;
    list-style: none;
    font-weight: 400;
    opacity: 0.7; }
    .footerRasil .footerNavList .footerNavButton {
      padding: 3px;
      display: inline;
      color: #ffffff; }
  .footerRasil .iconesRedesSociais {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    float: unset; }
    .footerRasil .iconesRedesSociais .faceLogo {
      height: 20px;
      padding-right: 20px; }
    .footerRasil .iconesRedesSociais .instaLogo {
      height: 20px; }

@media screen and (min-width: 1024px) {
  .footerRasil {
    padding: 60px 30px;
    width: 100%;
    position: relative; }
    .footerRasil .logoRasil {
      position: absolute;
      top: 15px;
      right: 50px; }
    .footerRasil .footerAddress {
      position: absolute;
      top: 20px;
      left: 50px;
      text-align: left;
      font-size: 13px;
      line-height: 22px; }
    .footerRasil .footerNavList {
      position: absolute;
      top: 50px;
      right: 46px; }
      .footerRasil .footerNavList .footerNavButton {
        font-size: 15px;
        text-align: right;
        color: #ffffff; }
    .footerRasil .iconesRedesSociais {
      position: absolute;
      top: 70px;
      right: 47px; }
      .footerRasil .iconesRedesSociais .faceLogo {
        padding-right: 30px; } }

.productsHeader {
  padding: 20px 15px; }
  .productsHeader .productsText {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 2.25;
    text-align: center;
    color: #2b2b2b;
    margin-bottom: 40px;
    position: relative; }
    .productsHeader .productsText::after {
      content: "";
      display: block;
      width: 30px;
      height: 3px;
      background-color: #071fc8;
      margin: 0 auto;
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0; }
  .productsHeader .productsCategories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .productsHeader .productsCategories .productsCategory {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #293340;
      text-transform: uppercase;
      border-radius: 22.5px;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.29);
      margin: 0 10px 20px;
      padding: 3px 20px; }
      .productsHeader .productsCategories .productsCategory:hover {
        background-color: #071fc8;
        color: white; }

.homeTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #54565a;
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px; }

.homeText {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #54565a;
  text-align: center;
  margin-bottom: 15px; }

.homeHistory {
  margin-bottom: 50px;
  padding: 20px 0; }
  .homeHistory .historyInfo {
    padding: 0 20px; }

@media screen and (min-width: 1024px) {
  .homeHistory {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
    padding: 50px 0;
    position: relative; }
    .homeHistory .historyInfo .homeTitle, .homeHistory .historyInfo .homeText {
      text-align: start; }
    .homeHistory .historyImageBox {
      height: 100%;
      overflow: hidden; }
      .homeHistory .historyImageBox .historyImage {
        max-width: unset;
        max-height: 100%; } }

.homeCompany {
  margin-bottom: 70px; }
  .homeCompany .companyInfo {
    padding: 20px; }
  .homeCompany .companyImagebox {
    max-width: calc(50% - 10px);
    display: inline-block;
    height: 260px;
    overflow: hidden; }
    .homeCompany .companyImagebox img {
      height: 100%;
      max-width: unset;
      width: auto; }
    .homeCompany .companyImagebox.companyImagebox1 {
      margin-right: 20px; }

@media screen and (min-width: 1024px) {
  .homeCompany {
    position: relative; }
    .homeCompany .companyInfo {
      padding-left: 450px;
      margin-bottom: 50px; }
      .homeCompany .companyInfo .homeTitle, .homeCompany .companyInfo .homeText {
        text-align: start; }
    .homeCompany .companyImagebox1 {
      position: absolute;
      height: 100%;
      width: 400px;
      top: 0; }
    .homeCompany .companyImagebox2 {
      max-width: 100%;
      width: 100%; }
      .homeCompany .companyImagebox2 .companyImage2 {
        height: auto;
        margin-left: 450px; } }

.homeQuality {
  padding: 20px; }
  .homeQuality .homeText {
    max-width: 800px;
    margin: 0 auto 50px; }
  .homeQuality .homeQualityInfos {
    display: grid; }
    .homeQuality .homeQualityInfos .homeQualityCard {
      margin-bottom: 30px; }
      .homeQuality .homeQualityInfos .homeQualityCard .certifiedBox {
        display: flex;
        align-items: center; }
        .homeQuality .homeQualityInfos .homeQualityCard .certifiedBox .certifiedImage {
          max-width: 120px;
          margin-right: 10px; }
        .homeQuality .homeQualityInfos .homeQualityCard .certifiedBox .certifiedLabel {
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          line-height: 1.7;
          color: #6d7783; }
      .homeQuality .homeQualityInfos .homeQualityCard .homeQualityTitle {
        font-family: 'Oswald', sans-serif;
        font-size: 24px;
        font-weight: 600;
        color: #354b84;
        text-transform: uppercase;
        margin-bottom: 30px;
        position: relative; }
        .homeQuality .homeQualityInfos .homeQualityCard .homeQualityTitle::after {
          content: "";
          display: block;
          width: 60px;
          height: 3px;
          background-color: #071fc8;
          position: absolute;
          bottom: -13px;
          left: 0; }
      .homeQuality .homeQualityInfos .homeQualityCard .homeQualityText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        line-height: 1.8;
        color: #54565a;
        margin-bottom: 10px; }

@media screen and (min-width: 1024px) {
  .homeQuality .homeQualityInfos {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px; } }

.representativesMap {
  padding: 0 5%;
  width: 100%;
  align-content: center;
  content: normal;
  content: initial;
  margin: 0; }
  .representativesMap img {
    width: 100%; }

@media screen and (min-width: 1024px) {
  .representativesMap {
    padding: 0 18% 0 0; } }

.representativesAddress {
  padding: 0 40px 0 40px;
  position: inherit; }
  .representativesAddress .addressTitle {
    font-family: 'Oswald', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #354b84;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative; }
    .representativesAddress .addressTitle::after {
      content: "";
      display: block;
      width: 60px;
      height: 3px;
      background-color: #071fc8;
      position: absolute;
      bottom: -13px;
      left: 0; }
  .representativesAddress .addressText {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 1.7;
    color: #6d7783; }

@media screen and (min-width: 768px) {
  .representativesAddress {
    padding: 0 6% 20px;
    max-width: 400px; }
    .representativesAddress .addressText {
      font-size: 15px; } }

@media screen and (min-width: 1024px) {
  .representativesAddress {
    position: relative;
    top: -150px;
    left: 63%; } }

.representativesInfo {
  width: 100%;
  padding: 20px 20px 0 20px;
  max-width: 90%;
  margin: auto;
  text-align: center; }

@media screen and (min-width: 768px) {
  .representativesInfo {
    padding: 20px 50px 0; }
    .representativesInfo .homeTitle {
      font-size: 35px; }
    .representativesInfo .homeText {
      font-size: 18px; } }

.qualityCard {
  max-width: 100%;
  display: inline-block; }
  .qualityCard .qualityCardImg {
    overflow: hidden;
    width: auto;
    max-height: 400px;
    margin-bottom: 30px;
    float: unset; }
  .qualityCard .qualityCardImg img {
    width: 100%;
    height: unset; }
  .qualityCard .qualityCardInfo {
    padding: 0 20px;
    display: inline-block; }
    .qualityCard .qualityCardInfo .qualityTitle {
      position: relative;
      display: inline-flex;
      font-family: 'Poppins', sans-serif;
      font-size: 40px;
      font-weight: 700;
      color: #354b84; }
      .qualityCard .qualityCardInfo .qualityTitle .qualityNumber {
        position: absolute;
        text-align: center;
        bottom: 70px;
        display: inline-block;
        width: 55px;
        height: 55px;
        padding: 17px;
        background-color: #354b84;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: #ffffff;
        margin-bottom: unset; }
    .qualityCard .qualityCardInfo .qualityText {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 16px;
      color: black;
      font-weight: 600px;
      max-width: unset; }
  .qualityCard .qualityLineDiv {
    display: block;
    width: 30px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #354b84;
    margin: 30px auto 30px; }

@media screen and (min-width: 768px) {
  .qualityCard .qualityCardInfo .qualityTitle {
    font-size: 44px; }
    .qualityCard .qualityCardInfo .qualityTitle .qualityNumber {
      width: 60px;
      height: 60px;
      padding: 18px;
      font-size: 18px; }
  .qualityCard .qualityCardInfo .qualityText {
    max-width: 700px;
    font-size: 19px; }
  .qualityCard .qualityCardInfo .qualityNumber {
    position: absolute;
    text-align: center;
    bottom: 70px;
    display: inline-block;
    width: 55px;
    height: 55px;
    padding: 17px;
    background-color: #354b84;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff; }
  .qualityCard .qualityCardInfo .qualityLineDiv {
    margin: 30px 0; } }

@media screen and (min-width: 1024px) {
  .qualityCard {
    width: 100%;
    position: relative; }
    .qualityCard .qualityCardImg {
      width: 100%;
      max-width: 50%;
      display: inline-block;
      margin: 0;
      height: 387px;
      float: left; }
    .qualityCard .qualityCardInfo {
      max-width: 485px;
      display: inline-block;
      height: 220px;
      margin: 100px 0 0 25px; }
      .qualityCard .qualityCardInfo .qualityNumber {
        top: 0;
        left: -75px; }
      .qualityCard .qualityCardInfo .qualityText {
        width: 100%; }
    .qualityCard:nth-child(even) {
      display: inline-block; }
      .qualityCard:nth-child(even) .qualityCardImg {
        float: right; }
      .qualityCard:nth-child(even) .qualityCardInfo {
        text-align: right;
        float: unset;
        margin: 100px 25px 0 0; }
      .qualityCard:nth-child(even) .qualityLineDiv {
        float: right; }
      .qualityCard:nth-child(even) .qualityNumber {
        position: absolute;
        left: unset;
        right: -75px; } }

.headerRasil {
  border: transparent;
  width: 100%;
  position: absolute;
  padding: 20px 20px; }
  .headerRasil .logoButton {
    display: inline-flex;
    position: relative;
    z-index: 2;
    text-shadow: 2px 2px 3px #0000008e; }
    .headerRasil .logoButton h1 {
      font-family: 'Open Sans';
      font-size: 22px;
      font-weight: 500;
      color: #ffffff; }
      .headerRasil .logoButton h1 strong {
        font-family: 'Montserrat';
        font-size: 23px;
        font-weight: 700; }
  .headerRasil .headerNav {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 70vw;
    height: 100vh;
    padding: 100px 20px 20px;
    background-color: #000000d7;
    transform: translate(-100%);
    transition: transform .3s ease; }
    .headerRasil .headerNav button {
      color: #cfd7e0;
      text-transform: uppercase;
      font-family: 'Open Sans';
      font-size: 20px;
      font-weight: 600;
      line-height: 55px;
      text-shadow: 2px 2px 3px #0000007e; }
    .headerRasil .headerNav :hover {
      color: #7d9ef3; }
    .headerRasil .headerNav.headerNavIsOpen {
      transform: translate(0); }

@media screen and (min-width: 1024px) {
  .headerRasil .headerNav {
    position: absolute;
    display: inline-flex;
    padding: 5px 50px 0 480px;
    width: 100%; }
    .headerRasil .headerNav ul {
      display: flex;
      width: 100%;
      justify-content: space-between; }
    .headerRasil .headerNav.headerNavIsOpen {
      background-color: #ffffff00; }
    .headerRasil .headerNav button {
      font-size: 18px;
      font-weight: 700; }
    .headerRasil .headerNav li {
      display: inline; } }
  .headerRasil .hamburgerBox {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px; }
    .headerRasil .hamburgerBox .hamburger {
      text-shadow: 2px 2px 2px #000000;
      width: 100%;
      height: 3px;
      position: relative;
      background-color: white; }
      .headerRasil .hamburgerBox .hamburger:before, .headerRasil .hamburgerBox .hamburger:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: transform .3s ease; }
      .headerRasil .hamburgerBox .hamburger:before {
        top: -10px; }
      .headerRasil .hamburgerBox .hamburger:after {
        top: 10px; }
    .headerRasil .hamburgerBox.isOpen .hamburger {
      height: 0; }
      .headerRasil .hamburgerBox.isOpen .hamburger:before {
        transform: rotate(45deg) translate(5px, 5px); }
      .headerRasil .hamburgerBox.isOpen .hamburger:after {
        transform: rotate(-45deg) translate(8px, -10px); }

@media screen and (min-width: 1024px) {
  .headerRasil .hamburgerBox {
    display: none; } }

@media screen and (min-width: 1024px) {
  .headerRasil {
    width: flex;
    padding: 20px 0 0 50px; }
    .headerRasil .logoButton h1 {
      font-size: 24px; }
      .headerRasil .logoButton h1 strong {
        font-size: 25px; } }

