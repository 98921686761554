.footerRasil{
  position: relative;
  height: auto;
  width: 100%;
  background-color: #354b84;
  padding: 20px 25px 0 25px;

  .logoRasil{
    text-align: center;
    margin-bottom: 10px;
    
    h1{
      font-family: 'Open Sans';
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;

      strong{
        font-family: 'Montserrat';
        font-size: 23px;
        font-weight: 700;
      }
    }
  }
  
  .footerAddress{
    position: relative;
    display: inline-flexbox;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    font-size: 13px;
    font-weight: 200;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    margin-bottom: 15px;
    
  }

  .footerNavList{
    display: flex;
    justify-content: center;  
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-align: center;
    list-style: none;  
    font-weight:400;
    opacity: 0.7;


    .footerNavButton{
      padding: 3px;
      display: inline;
      color: #ffffff;

    }
  }
  
  .iconesRedesSociais{
    text-align: center;
    display:flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    float: unset;
    
    .faceLogo{
      height: 20px;
      padding-right: 20px;
    }
    .instaLogo{
      height: 20px;
    }
  }  
  @media screen{
    @media (min-width: 1024px) {
      padding: 60px 30px;
      width: 100%;
      position: relative;

      .logoRasil{
        position: absolute;
        top: 15px;
        right: 50px;
      }

      .footerAddress{
        position: absolute;
        top: 20px;
        left: 50px;
        text-align: left;
        font-size: 13px;
        line-height: 22px;


      }
      .footerNavList{
        position: absolute;
        top: 50px;
        right: 46px;

        .footerNavButton{
        font-size: 15px;
        text-align: right;
        color: #ffffff;
        }

      }
      
      .iconesRedesSociais{
        position: absolute;
        top: 70px;
        right: 47px;

        
        .faceLogo{
          padding-right: 30px;

        }

      }  
    }
  }
}