.headerRasil{
  border: transparent;
  width: 100%;
  position: absolute;
  padding: 20px 20px ;
  

  .logoButton{
    display: inline-flex;
    position: relative;
    z-index: 2;
    text-shadow: 2px 2px 3px #0000008e;
    
    h1{
      font-family: 'Open Sans';
      font-size: 22px;
      font-weight: 500;
      color: #ffffff;

      strong{
        font-family: 'Montserrat';
        font-size: 23px;
        font-weight: 700;
      }
    }
  }

  .headerNav{
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 70vw;
    height: 100vh;
    padding: 100px 20px 20px;
    background-color: #000000d7;
    transform: translate(-100%);
    transition: transform .3s ease;

    button{
      color: #cfd7e0;
      text-transform: uppercase;
      font-family: 'Open Sans';
      font-size: 20px;
      font-weight: 600;
      line-height: 55px;
      text-shadow: 2px 2px 3px #0000007e;
    }
    
    & :hover{
      color: #7d9ef3;
      



    }
    &.headerNavIsOpen {
      transform: translate(0);

    }
    @media screen {
      @media (min-width: 1024px) {
        position: absolute;
        display:inline-flex;
        padding: 5px 50px 0 480px;
        width: 100%;
        ul{
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        &.headerNavIsOpen {
          background-color: #ffffff00;
        }



        button{
          font-size: 18px;
          font-weight: 700;

        }
        li{
          display: inline;
        }
      }
    }
  }

  .hamburgerBox{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;

    .hamburger {
      text-shadow: 2px 2px 2px #000000;
      width: 100%;
      height: 3px;
      position: relative;
      background-color: white;

      &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: transform .3s ease;
      }

      &:before {
        top: -10px
      }

      &:after {
        top: 10px;
      }
    }

    &.isOpen {
      .hamburger {
        height: 0;

        &:before {
          transform: rotate(45deg) translate(5px, 5px);
        }
        &:after {
          transform: rotate(-45deg) translate(8px, -10px);
        }
      }
    }
    @media screen {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }  
  @media screen {
    @media (min-width: 1024px) {
      width: flex;
      padding: 20px 0 0 50px;

      .logoButton{
        h1{
          font-size: 24px;
    
          strong{
            font-size: 25px;
          }
        }
      }
    }
  }
}