
.contactForm {
  padding: 20px;
  
    input, textarea {
      display: block;
      width: 100%;
      border: solid 1px #cacaca;
      padding: 10px;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      line-height: 1.6;
      margin-bottom: 20px;
    
      &::placeholder {
        color: #aeb7c1;
        padding: 10px;
      }
    }

    textarea {
      height: 160px;
    }
  
    @media screen {
      @media (min-width: 768px) {
        input {
          display: inline-flex;
          width: calc(50% - 10px);
          
          &:first-child {
            margin-right: 20px;
          }
        }
      }
        
      @media (min-width: 1024px) {
        width: calc(100% - 320px);
        padding-top: 50px;
      }
    } 
  } 