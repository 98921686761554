@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:400,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img{
  max-width:100%;
}

li {
  list-style-type: none;
}


button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}


.container {
  max-width: 1024px;
  margin: auto;
}

.jumbotron {
  height: 450px;
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #fff;
    text-shadow: 4px 2px 3px #000000;
    text-align: center;
  }
}



.textoIsodiv {
  width: 139px;
  height: 110px;
  text-align: center;
  display: inline-block;
  position: relative; top: -20px; left: 10px;
  
  .textoseloiso {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 2;
    text-align: center;
    color: #6d7783;
  }
}

.Iso9001 {
  width: 127px;
  height: 109px;
}

.contactInfo {
  padding: 50px 20px;
  width: 100%;
  
  .contactCard {
    margin-bottom: 40px;
    
    h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
      color: #363d4f;
      border-bottom: 4px solid #071fc8;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    
    p {
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      color: #6d7783;
    }
  }
  
  @media screen{
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px;
    }
    
    @media (min-width: 1024px) {
      display: block;
      max-width: 320px;
      float: right;
    }
  }
}


.contactButton {
  padding: 12px 50px;
  background-color: #354b84;
  font-family: 'Oswald', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}


@import './styles/Components/ContactForm.scss';
@import './styles/Components/JumbotronHome.scss';
@import './styles/Components/Representatives.scss';
@import './styles/Components/FooterRasil.scss';
@import './styles/Components/productsHeader.scss';
@import './styles/Components/Home.scss';
@import './styles/Components/HomeHistory.scss';
@import './styles/Components/HomeCompany.scss';
@import './styles/Components/HomeQuality.scss';
@import './styles/Components/RepresentativesMapSp.scss';
@import './styles/Components/RepresentativesAddress.scss';
@import './styles/Components/RepresentativesInfo.scss';
@import './styles/Components/Quality.scss';
@import './styles/Components/QualityCard.scss';
@import './styles/Components/HeaderRasil.scss';

